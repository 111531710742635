<template>
  <v-container>
    <v-row >
      <v-col sm12>A página não existe.</v-col>
    </v-row >
  </v-container>
</template>

<script>

export default {
  beforeMount() {
    this.$auth.logout();
  }
};
</script>

<style>
</style>
